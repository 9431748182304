import { useStopClickEventsPropagation } from '@core/hooks/useStopDurationClickEventsPropagation.hook';
import { AtomicFunctionComponentWithChildren } from '@core/types/functionComponent.types';
import { Size, SizeXsToLg } from '@core/types/size.types';

import classNames from 'classnames';
import Link, { LinkProps } from 'next/link';
import { ReactNode } from 'react';

export interface BasicLinkProps extends Omit<LinkProps, 'href'> {
  href?: string;
  rel?: string;
  disabled?: boolean;
  textSize?: SizeXsToLg;
  openInNewTab?: boolean;
  rightIcon?: ReactNode;
  leftIcon?: ReactNode;
  onlyIcon?: ReactNode;
  noHoverEffect?: boolean;
  isUnderline?: boolean;
  noBold?: boolean;
  color?: 'catalina' | 'persian';
}

export const BasicLink: AtomicFunctionComponentWithChildren<BasicLinkProps> = ({
  children,
  href,
  rel,
  leftIcon,
  rightIcon,
  onlyIcon,
  onClick,
  disabled,
  textSize = Size.Sm,
  openInNewTab = false,
  noHoverEffect = false,
  noBold = false,
  isUnderline = false,
  color = 'catalina-blue',
  ...props
}) => {
  const stopClickEventsPropagationProps = useStopClickEventsPropagation();

  const LinkContent =
    onlyIcon || leftIcon || rightIcon ? (
      <div className="flex items-center justify-center">
        {onlyIcon ? (
          <span>{onlyIcon}</span>
        ) : (
          <>
            {leftIcon && <span className="mr-1.5">{leftIcon}</span>}
            {children}
            {rightIcon && <span className="ml-1.5">{rightIcon}</span>}
          </>
        )}
      </div>
    ) : (
      children
    );

  return (
    <span
      className={classNames('min-w-0 cursor-pointer', {
        'pointer-events-none cursor-not-allowed text-grey': disabled,

        'text-persian-blue': color === 'persian' && !disabled,
        'text-catalina-blue': color === 'catalina' && !disabled,

        /** text sizes */
        'text-xs': textSize === Size.Xs,
        'text-sm': textSize === Size.Sm,
        'text-base': textSize === Size.Md,
        'text-lg': textSize === Size.Lg,

        /** hover effect */
        'hover:text-klein-blue': !noHoverEffect,
        'hover:underline': !noHoverEffect && color === 'catalina',

        'font-semibold': !noBold,

        underline: isUnderline,
      })}
      {...stopClickEventsPropagationProps}
    >
      {href ? (
        <Link
          href={href}
          rel={rel}
          target={openInNewTab ? '_blank' : '_self'}
          {...props}
        >
          {LinkContent}
        </Link>
      ) : (
        <a onClick={onClick} {...props}>
          {LinkContent}
        </a>
      )}
    </span>
  );
};
