import { BasicLink } from '@core/components/Links/BasicLink.component';
import Error from 'next/error';
import { Routes } from 'router/routes';

export default function NotFoundErrorPage() {
  const isProduction = process.env.NEXT_PUBLIC_ENV === 'production';
  return (
    <div>
      {isProduction ? null : (
        <div className="absolute">
          Maybe you`&lsquo;`re looking the{' '}
          <BasicLink href={Routes.CHEATCODE}> cheatcode page </BasicLink>or the
          <BasicLink href={Routes.ADMIN_STAFFING}>muzzichien page</BasicLink>
        </div>
      )}
      <Error statusCode={404} />
    </div>
  );
}
